import React, { useCallback, useEffect, useRef, useState } from 'react'
import GallerySlider from './gallery/GallerySlider';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { deepCopy } from '../utils/utils';
import Description from './description/Description';
import submitedImg from '../assets/images/feedbackSubmit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';



const ProductCard = ({ product, paths, handleQuickView, handleAddToCart, handleOpenEmailModal, handleActiveDes, activeDes, submittedFeedbacks, handleGalleryImagesModal }) => {
    const [singleProduct, setSingleProduct] = useState(null);
    const [files, setFiles] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [baseUrls, setBaseUrls] = useState(null);
    const [manfactureInfo, setManfactureInfo] = useState(null);
    const [addedToCart, setAddedToCart] = useState(false);
    const [showDesc, setShowDesc] = useState(false);
    const [productBackdrop, setProductBackdrop] = useState(false);
    const countRef = useRef(null);
    const countTimeoutIdRef = useRef(null);
    const productCardContainerRef = useRef(null);
    const productCardInnerWrapperRef = useRef(null);
    const [submittedFeedbackOpt, setSubmittedFeedbackOpt] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    // const navigate = useNavigate(); 




    useEffect(() => {
        const productCopy = deepCopy(product);
        const pathCopy = deepCopy(paths);
        // console.log(productCopy);
        // console.log(paths)
        setBaseUrls(pathCopy);
        setFiles(productCopy?.files);
        setSingleProduct(prevState => ({
            ...prevState,
            id: productCopy?.id,
            name: productCopy?.name,
            title: productCopy?.title,
            manufacturer_id: productCopy?.manufacturer_id,
            description: productCopy?.description,
            website: productCopy?.website,
            zip_path: productCopy?.zip_path
        }));
        setManfactureInfo(productCopy?.manufacturer_name)
        setGalleryImages(productCopy?.images);

    }, [product, paths])


    const showHideCount = useCallback(() => {
        const showClass = 'show';
        const displayClass = 'd-none';
        const countWrapper = countRef.current;
        // const tagsWrapper = proTagsRef.current;

        if (countWrapper.classList.contains(showClass)) {
            // Clear any existing timeout.
            clearTimeout(countTimeoutIdRef.current);

            countTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);

        } else {
            // tagsWrapper.classList.add(displayClass);
            countWrapper.classList.add(showClass);

            // Clear any existing timeout.
            clearTimeout(countTimeoutIdRef.current);

            countTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);
        }
    }, []);

    useEffect(() => {
        setShowDesc(activeDes && activeDes === singleProduct?.id ? true : false);
    }, [activeDes, singleProduct])

    useEffect(() => {
        if (submittedFeedbacks.length > 0 && singleProduct) {
            const checkFeed = submittedFeedbacks.some(feedback => feedback?.id === singleProduct?.id);
            const submittedOpt = submittedFeedbacks.filter(feedback => feedback?.id === singleProduct?.id)[0];
            if (submittedOpt) {
                const interestType = submittedOpt?.feedbacktype === "1" ? 'Interested' : submittedOpt?.feedbacktype === "2" ? 'Maybe In the Future' : submittedOpt?.feedbacktype === "4" ? 'Previously Specified' : 'Not Interested';
                submittedOpt.interestType = interestType;
            }
            setSubmittedFeedbackOpt(submittedOpt);
            setIsSubmitted(checkFeed);
        }
    }, [submittedFeedbacks, singleProduct])



    // const addProductToCart = (e) => {
    //   setAddedToCart(!addedToCart);
    //   handleAddToCart(product)
    // }

    const handleEmailModalValidate = (intType) => {
        setShowDesc(false);
        if (!isSubmitted) {
            const interestType = intType === "Interested" ? '1' : intType === "maybe_in_future" ? '2' : intType === "previously_specified" ? '4' : '3'
            let body = {
                comment: "",
                request_info: [],
                feedback: [],
                reference1: "",
                reference2: ""
            }
            handleOpenEmailModal(singleProduct?.id, interestType, body);

        }
    }

    const handleShowDesc = () => {
        handleActiveDes(!showDesc ? singleProduct?.id : null);
        setShowDesc(!showDesc);
        if (window.innerWidth > 575) {
            // console.log("ifff")
            setProductBackdrop(prevbackDrop => !prevbackDrop);
        }
        else {
            setProductBackdrop(false);
        }
    }

    // const observer = new IntersectionObserver(entries=> {

    //     if (!entries[0].isIntersecting) {
    //         // Set showDesc state to false
    //         setShowDesc(false);
    //         // Disconnect the observer to stop observing
    //         observer.disconnect();
    //     }
    // },{
    //     threshold: 0.1,
    // })

    useEffect(() => {

        if (showDesc && productBackdrop) {
            productCardInnerWrapperRef.current.classList.add('show-backdrop');
        } else {
            productCardInnerWrapperRef.current.classList.remove('show-backdrop');
        }
        if (showDesc && !productBackdrop) {
            // console.log("showDesc &&!productBackdrop", showDesc ,"&&" ,!productBackdrop);
            productCardInnerWrapperRef.current.classList.add('product-card-mobile');
            // observer.observe(productCardInnerWrapperRef.current);
        }
        if (!showDesc && !productBackdrop) {
            // console.log("!showDesc &&!productBackdrop" , !showDesc ,"&&" ,!productBackdrop);
            productCardInnerWrapperRef.current.classList.remove('product-card-mobile');

        }

    }, [showDesc, productBackdrop]);

    const handleGalleryModal = (selectedImage) => {
        if (window.innerWidth > 768) {
            const mrfLogo = manfactureInfo?.logo ? `${baseUrls?.logo_path}/${manfactureInfo?.logo}` : null;
            const fileFullUrl = files?.map(file => ({ ...file, file_path: `${baseUrls?.files_path}/${file?.file}` }));
            handleGalleryImagesModal(galleryImages, selectedImage, mrfLogo, fileFullUrl, 0, singleProduct?.zip_path);
        }
    }

    // const detailPage = () => {
    //     // console.log("files::",files)
    //     const fileFullUrl = files?.map(file => ({ ...file, file_path: `${baseUrls?.files_path}/${file?.file}` }));
    //     // console.log("fileFullUrl::",fileFullUrl)
    //     const detailData={
    //         product: product,
    //         fileUrl: fileFullUrl,
    //         paths: paths
    //     }
    //     localStorage.setItem('single_product', JSON.stringify(detailData));
    //     setTimeout(() => {
    //         navigate(`/product/product-detail`);
    //     }, 10);
    // }




    return (
        <div className="col-sm-6 col-md-6 col-lg-4 col-12">
            {/* <FadeIn delay={0}> */}
            <div className="product-card-grid-wrapper">
                <div className="product-card-inner-wrapper" ref={productCardInnerWrapperRef}>{showDesc && productBackdrop && <div className="product-overlay" onClick={handleShowDesc}></div>}
                    <div className="product-card-container" ref={productCardContainerRef} >
                        <div className="product-card-header">
                            <div className="product-img-slider-wrapper">
                                <GallerySlider content={singleProduct?.description} imgBaseUrl={baseUrls?.image_path} logoBaseUrl={baseUrls?.logo_path} manfacture={manfactureInfo} galleryImages={galleryImages} showHideCount={showHideCount} countRef={countRef} handleGalleryModal={handleGalleryModal} />
                                {/* <div className='overlay' onClick={handleGalleryModal}></div> */}
                            </div>
                            {/* <div className="tags-container" ref={proTagsRef}>
                                    <div className="tags-inner-container">
                                        <div className="tag product-from"> <span>listed by redfin 14 hr ago</span></div>
                                        <div className="tag type"> <span>3d walkthrough</span></div>
                                    </div>
                                    <div className="fav-container">
                                                <i className="far fa-heart"></i>
                                    </div>
                                </div> */}

                        </div>
                        <div className={`product-card-body `}>
                            <div className="product-title-container">
                                {/* <input type='checkbox' name='product' id='product'/>  */}
                                {/* <div className="checkbox-wrapper-custom">
                                        <input id={inputId} type="checkbox" checked={addedToCart} onChange={()=>setAddedToCart(!addedToCart)}/>
                                        <label className="cbx" htmlFor={inputId}>
                                            <div className="flip">
                                            <div className="front"></div>
                                            <div className="back">
                                                <svg width="16" height="14" viewBox="0 0 16 14">
                                                <path d="M2 8.5L6 12.5L14 1.5"></path>
                                                </svg>
                                            </div>
                                            </div>
                                        </label>
                                        </div> */}
                                {/* {!isSubmitted && */}

                                <h2 className="product-name" title={singleProduct?.name}

                                //   onClick={detailPage}
                                >
                                    {/* <label htmlFor={inputId}><span>{(Math.floor(Math.random() * 11))}.</span> James Hardie 12</label> */}
                                    {singleProduct?.name}
                                </h2>

                                {/* } */}
                                <div className="actions-wrapper submitted-image-wrapper">
                                    {/* <div className={`action download quick-review`} title="Share">
                                        <i className="fa fa-share-square"></i>
                                        </div> */}

                                    {/* {!isSubmitted &&
                                        <div className={`action heart-action`} title="I Love it!" onClick={() => handleEmailModalValidate("I Love it")}> <i className="far fa-heart"></i> </div>
                                        } */}
                                    {/* {isSubmitted && 
                                                <img src={submitedImg} width={36} height={30} alt={`feedbackSubmited${singleProduct?.id}`} aria-label='checkedImage'/>
                                        } */}
                                    {/* <div className={`action quick-review${showDesc ? ' active' : ''}`} title="Quick View" onClick={() => handleShowDesc()}> <i className="fa fa-search"></i> </div> */}
                                </div>
                            </div>


                            {/* {!isSubmitted && */}
                            <>
                                <p className={`product-title${showDesc ? ' active' : ''}`}>{singleProduct?.title} </p><div className='readmore' onClick={() => handleShowDesc()}> Read {showDesc ? 'Less' : 'More'}  </div>
                            </>
                            {/* } */}
                            {/* <div className="price-container"><h2 className="price-title">$30,023</h2></div> */}
                            {isSubmitted &&
                                <div className='py-0'>
                                    {isSubmitted &&
                                        <span className='share-message p-1'> Your Feedback</span>
                                    }
                                    {/* <p className='text-start m-0 pt-1'>
                                        {submittedFeedbackOpt?.feedbacktype === "1" && <FontAwesomeIcon icon={faThumbsUp} />}
                                        {submittedFeedbackOpt?.feedbacktype === "3" && <FontAwesomeIcon icon={faThumbsDown} />}

                                        <span className='ps-1 fw-bold'> {submittedFeedbackOpt?.interestType}</span>
                                    </p> */}
                                </div>
                            }
                        </div>

                        <div className="product-card-footer">

                            {showDesc && (
                                <>
                                    <div className="custom-divider"><hr className='my-0' /></div>
                                    <div className="description-container">
                                        <Description content={singleProduct?.description} />
                                    </div>
                                </>
                            )
                            }
                            {!isSubmitted &&
                                <span className='share-message'>Share Your Feedback</span>
                            }

                            <div className="product-card-inner-footer">
                                {isSubmitted &&
                                    <>
                                        <div className="action add-to-cart submitted-wrapper w-100 feed-submitted-message" title='Feedback Submitted.'>
                                            <h4 className="form-status-title text-start">Feedback Submitted!</h4><br />
                                            {/* <img src={submitedImg} width={36} height={30} alt={`feedbackSubmited${singleProduct?.id}`} aria-label='checkedImage'/> */}


                                        </div>

                                    </>
                                }
                                {!isSubmitted &&
                                    <>
                                        {/* <div className={`action add-to-cart${addedToCart ? ' added' : ''}`} title='Not Interested' onClick={() => handleEmailModalValidate("Not Interested")}>
                                            <i className="far fa-thumbs-down"></i> 
                                            <FontAwesomeIcon icon={faThumbsDown} />
                                            <span aria-label="Not Interested">Not Interested </span>
                                        </div> */}

                                        <div className={`action add-to-cart${addedToCart ? ' added' : ''}`} title='Interested' onClick={() => handleEmailModalValidate("Interested")}>
                                            {/* <i className="far fa-thumbs-up"></i> */}
                                            {/* <FontAwesomeIcon icon={faThumbsUp} /> */}
                                            <span aria-label="Interested">Interested </span>
                                        </div>
                                        <div className={`action add-to-cart${addedToCart ? ' added' : ''}`} title='Maybe in the future' onClick={() => handleEmailModalValidate("maybe_in_future")}>
                                            {/* <i className="far fa-thumbs-up"></i> */}

                                            <span aria-label="Maybe in the future">Maybe in the future </span>
                                        </div>

                                        {/* <div className={`action add-to-cart ${addedToCart ? ' added' : ''}`} title='Previously Specified' onClick={() => handleEmailModalValidate("previously_specified")}>
                                            <span aria-label="Previously Specified">Previously Specified </span>
                                        </div>  */}

                                    </>
                                }

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            {/* </FadeIn> */}
        </div>
    )
}

export default ProductCard
